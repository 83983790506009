@charset "UTF-8";
/*提示字体颜色*/
/*弱提示背景色*/
/*圆角大小*/
/*间距8的倍数*/
/**
    flex布局
 */
/**
    flex布局
 */
/**
      文本布局
   */
/**
      flex布局上下左右居中
   */
/**
    文本布局
 */
/**
    渐变背景
 */
/*阴影*/
/* 超出显示省略号... */
.ding-scan-login {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 15%;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: #fff;
  padding-top: 0.2rem;
  border-radius: 0.02rem;
}
.ding-scan-login .scan-title {
    text-align: center;
    font-size: 0.2rem;
    font-weight: 600;
    margin-top: 0.04rem;
}
.ding-scan-login .ding-logo {
    width: 0.4rem;
    height: 0.4rem;
    margin: 0 auto;
}
.module-qrcode-wrapper-iframe .module-qrcode-login-title {
  display: block !important;
}
